<template>
  <v-dialog
    :value="dialog"
    max-width="90%"
    width="unset"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card v-if="records.length > 0" class="pa-5">
      <dynamic-data-table
        :hide-footer="true"
        :project-id="relation.related_project"
        :table-columns="columns"
        :table-headers="headers"
        :table-id="relation.related_table"
        :table-records="records"
        table-title="Record"
      />
    </v-card>
    <v-card v-else class="pa-5">
      <ant-loading />
    </v-card>
  </v-dialog>
</template>

<script>
import { getColumns } from '@/services/api/column.api';
import { getRecord } from '@/services/api/record.api';
import DataHelper from '@/services/data-helper';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading';

export default {
  name: 'TableObjectDialog',
  components: {
    AntLoading,
    DynamicDataTable: () => import('../DynamicDataTable.vue'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    relation: {
      type: Object,
      required: true,
    },
    record: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      headers: [],
      columns: [],
      records: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['projectTables']),
  },
  watch: {
    dialog(value) {
      if (value) {
        if (
          this.relation.related_column === null &&
          this.record.length === 36
        ) {
          this.loading = true;
          this.fetchObjectData();
        }
      } else {
        this.headers = [];
        this.columns = [];
        this.records = [];
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    fetchObjectData() {
      getColumns(
        this.relation.related_project,
        this.relation.related_table
      ).then((columns) => {
        this.columns = columns;
        this.headers = DataHelper.getTableColumns(columns, false, false);
      });
      getRecord(
        this.relation.related_project,
        this.relation.related_table,
        this.record
      ).then((record) => {
        this.records.push(record);
      });

      this.loading = false;
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-dialog
    :value="dialog"
    max-width="90%"
    width="unset"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card v-if="loading" class="pa-5">
      <ant-loading />
    </v-card>
    <v-card v-else-if="relationsData.length > 0" class="pa-5">
      <div
        v-for="{
          table: { id: tableId, project: tableProject, name: tableName },
          tableColumns,
          tableRelations,
          records,
        } in relationsData"
        :key="tableId"
        class="pb-4"
      >
        <div class="font-weight-bold">{{ tableName }}</div>
        <dynamic-data-table
          :can-view-relations="true"
          :has-top="false"
          :hide-footer="true"
          :project-id="tableProject"
          :table-columns="tableColumns"
          :table-headers="getTableHeaders(tableColumns)"
          :table-id="tableId"
          :table-records="records"
          :table-relations="tableRelations"
          table-title="Record"
        />
      </div>
    </v-card>
    <v-card v-else class="pa-5"> Nothing</v-card>
  </v-dialog>
</template>

<script>
import { recordsRelations } from '@/services/api/record.api';
import DataHelper from '@/services/data-helper';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading';

export default {
  name: 'TableRelatedRecordsDialog',
  components: {
    AntLoading,
    DynamicDataTable: () => import('../DynamicDataTable.vue'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    relations: {
      type: Array,
      required: true,
    },
    record: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      relationsData: [], // [ { table, records }, ]
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['projectTables']),
    project() {
      return this.relations?.[0]?.related_project;
    },
    table() {
      return this.relations?.[0]?.related_table;
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        this.loading = true;
        this.fetchObjectData().finally(() => {
          this.loading = false;
        });
      } else {
        this.headers = [];
        this.columns = [];
        this.records = [];
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    async fetchObjectData() {
      this.relationsData = await recordsRelations({
        record: this.record,
        projectId: this.project,
        tableId: this.table,
      });
    },
    getTableHeaders(columns) {
      return DataHelper.getTableColumns(columns, true, false);
    },
  },
};
</script>

<style scoped></style>
